<i18n>
{
  "ru": {
    "title": "Печать заказа",
    "total": "Итого",
    "receipts": "Прием",
    "delivery": "Доставка",
    "allSum": "Общая сумма",
    "paid": "Оплачено",
    "diff": "Осталось",
    "changeReceipts": "Изменить прием",
    "changeDelivery": "Изменить доставку",
    "contractorsInWorks": "(У&nbsp;подрядчика)",
    "newPhoto": "Новые фотографии",
    "uploadPhoto": "Загруженные фотографии"
  },
  "he": {
    "title": "הדפסת סדר",
    "total": "סה''כ",
    "receipts": "קבלה",
    "delivery": "משלוח",
    "allSum": "סך הכל",
    "paid": "בתשלום",
    "diff": "נשאר",
    "changeReceipts": "שינוי הקבלה",
    "changeDelivery": "שנה משלוח",
    "contractorsInWorks": "(נשלח&nbsp;לקבלן)",
    "newPhoto": "תמונות חדשות",
    "uploadPhoto": "תמונות שהועלו"
  }
}
</i18n>

<template>
  <div class="print">
    <h1 class="text-h4 mb-2 no-print">{{ $t('title') }}</h1>

    <template v-if="!loading">
      <v-row class="mb-5">
        <template v-for="(item, index) in propsSystem">
          <v-col
            :cols="item.col || 3"
            :key="`prop-item-system-${index}`"
          >
            <template v-if="item.type === 'text'">
              <v-text-field
                v-model="item.value"
                :label="item.name"
                disabled
                color="teal"
              ></v-text-field>
            </template>

            <template v-if="item.type === 'select'">
              <v-select
                v-model="item.value"
                :items="item.list"
                item-text="name"
                item-value="id"
                :label="item.name"
                disabled
                color="teal"
              ></v-select>
            </template>

            <template v-else-if="item.type === 'user'">
              <AutocompleteUser
                v-model="item.value"
                :label="item.name"
                disabled
              ></AutocompleteUser>
            </template>
          </v-col>
        </template>

        <template v-for="(item, index) in propsInfo">
          <v-col
            :cols="item.col || 3"
            :key="`prop-item-info-${index}`"
          >
            <template v-if="item.type === 'text'">
              <v-text-field
                v-model="item.value"
                :label="item.name"
                disabled
                color="teal"
              ></v-text-field>
            </template>

            <template v-if="item.type === 'number'">
              <v-text-field
                v-model.number="item.value"
                :label="item.name"
                disabled
                color="teal"
              ></v-text-field>
            </template>

            <template v-if="item.type === 'select'">
              <v-select
                v-model="item.value"
                :items="item.list"
                item-text="name"
                item-value="id"
                :label="item.name"
                disabled
                color="teal"
              ></v-select>
            </template>

            <template v-if="item.type === 'payments'">
              <v-select
                v-model="item.value"
                :items="paymentsClient"
                item-text="name"
                item-value="id"
                :label="item.name"
                disabled
                color="teal"
              ></v-select>
            </template>

            <template v-if="item.type === 'paid'">
              <v-select
                v-model="item.value"
                :items="item.list"
                item-text="name"
                item-value="id"
                :label="item.name"
                disabled
                color="teal"
              ></v-select>
            </template>

            <template v-if="item.type === 'paidSum'">
              <v-text-field
                v-model.number="item.value"
                :label="item.name"
                disabled
                color="teal"
              ></v-text-field>
            </template>

            <template v-else-if="item.type === 'delivery'">
              <SelectDelivery
                v-model="item.value"
                :label="item.name"
                disabled
              ></SelectDelivery>
            </template>

            <template v-else-if="item.type === 'receipts'">
              <SelectReceipts
                v-model="item.value"
                :label="item.name"
                disabled
              ></SelectReceipts>
            </template>

            <template v-else-if="item.type === 'client'">
              <AutocompleteClient
                v-model="item.value"
                :label="item.name"
                disabled
              ></AutocompleteClient>
            </template>
          </v-col>
        </template>
      </v-row>

      <v-data-table
        :headers="headers"
        :items="goods"
        :items-per-page="-1"
        hide-default-footer
        class="elevation-1"
        group-by="UF_GROUP"
      >
        <template v-slot:group.header="{items}">
          <th colspan="4">
            {{ (find = goodsGroups.find((o) => o.ID === items[0].UF_GROUP)) ? find.UF_NAME : '-' }}
          </th>
        </template>

        <template v-slot:item.UF_COMPLETED="{ item }">
          {{ (item.UF_COMPLETED === '1') ? 'Да' : 'Нет' }}
        </template>
      </v-data-table>

      <div class="text-right text-h5 mt-3">
        Итого: {{ allSum }}
      </div>

      <v-row>
        <template v-for="(item, index) in propsDopInfo">
          <v-col
            cols="12"
            :key="`prop-item-dop-info-${index}`"
          >
            <template v-if="item.type === 'textarea'">
              <v-textarea
                v-model="item.value"
                :label="item.name"
                disabled
                color="teal"
              ></v-textarea>
            </template>
          </v-col>
        </template>
      </v-row>

      <div class="text-right text-h5 mt-3">
        <p>
          {{ $t('receipts') }}: {{ receiptPrice }}
          <br>{{ $t('delivery') }}: {{ deliveryPrice }}
          <br>{{ $t('allSum') }}: {{ orderSum }}
        </p>

        <p>
          {{ $t('paid') }}: {{ paidSumComp }}
          <br>{{ $t('diff') }}: {{ paidSumStayComp }}
        </p>
      </div>

      <v-row class="mt-5 no-print">
        <v-col
          cols="auto"
        >
          <v-btn
            color="teal"
            dark
            @click="print()"
          >{{ $t('btn.print') }}</v-btn>
        </v-col>

        <v-col
          cols="auto"
        >
          <v-btn
            color="grey lighten-5"
            :to="`/orders/${id}`"
          >{{ $t('btn.cancel') }}</v-btn>
        </v-col>
      </v-row>
    </template>
  </div>
</template>

<script>
import AutocompleteClient from '@/components/AutocompleteClient.vue';
import AutocompleteUser from '@/components/AutocompleteUser.vue';
import SelectDelivery from '@/components/SelectDelivery.vue';
import SelectReceipts from '@/components/SelectReceipts.vue';

export default {
  name: 'OrderPrint',
  components: {
    AutocompleteClient,
    AutocompleteUser,
    SelectDelivery,
    SelectReceipts,
  },
  data: (vm) => ({
    loading: true,
    clientSelect: {},
    deliveryPrice: 0,
    receiptPrice: 0,
    props: [
      {
        name: vm.$t('props.ORDER_ID'),
        code: 'ID',
        type: 'text',
        value: '',
        group: 'system',
        col: '2',
      },
      {
        name: vm.$t('props.UF_DATE'),
        code: 'UF_DATE',
        type: 'text',
        value: '',
        group: 'system',
        col: '2',
      },
      {
        name: vm.$t('props.UF_TIME'),
        code: 'UF_TIME',
        type: 'text',
        value: '',
        group: 'system',
        col: '2',
      },
      {
        name: vm.$t('props.UF_USER'),
        code: 'UF_USER',
        type: 'user',
        value: null,
        list: [],
        group: 'system',
      },
      {
        name: vm.$t('props.UF_STATUS'),
        code: 'UF_STATUS',
        type: 'select',
        value: null,
        list: [
          {
            name: vm.$t('status.orders.inWork'),
            id: 1,
          },
          {
            name: vm.$t('status.orders.contractorsInWorks'),
            id: 5,
          },
          {
            name: vm.$t('status.orders.done'),
            id: 2,
          },
          {
            name: vm.$t('status.orders.completed'),
            id: 3,
          },
          {
            name: vm.$t('status.orders.finished'),
            id: 4,
          },
        ],
        group: 'system',
      },
      {
        name: vm.$t('props.UF_CLIENT'),
        code: 'UF_CLIENT',
        type: 'client',
        value: null,
        list: [],
        group: 'info',
        col: 6,
      },
      {
        name: vm.$t('props.UF_PAYMENT'),
        code: 'UF_PAYMENT',
        type: 'payments',
        value: null,
        list: [],
        group: 'info',
        col: 6,
      },
      {
        name: vm.$t('props.UF_PAID'),
        code: 'UF_PAID',
        type: 'paid',
        value: 3,
        list: [
          {
            id: 3,
            name: vm.$t('status.paid.no'),
          },
          {
            id: 4,
            name: vm.$t('status.paid.partly'),
          },
          {
            id: 5,
            name: vm.$t('status.paid.yes'),
          },
          {
            id: 6,
            name: vm.$t('status.paid.noPaid'),
          },
        ],
        group: 'info',
        col: 6,
      },
      {
        name: vm.$t('props.UF_PAID_SUM'),
        code: 'UF_PAID_SUM',
        type: 'paidSum',
        value: 0,
        group: 'info',
        col: '6',
      },
      {
        name: vm.$t('props.UF_RECEIPTS'),
        code: 'UF_RECEIPTS',
        type: 'receipts',
        value: null,
        group: 'info',
        col: 6,
      },
      {
        name: vm.$t('props.UF_DELIVERY'),
        code: 'UF_DELIVERY',
        type: 'delivery',
        value: null,
        group: 'info',
        col: 6,
      },
      {
        name: vm.$t('props.UF_COMMENT'),
        code: 'UF_COMMENT',
        type: 'textarea',
        value: null,
        group: 'dopInfo',
      },
      {
        name: vm.$t('goods'),
        code: 'goods',
        group: 'goods',
        value: [],
      },
    ],
    headers: [
      {
        text: vm.$t('props.UF_COMPLETED'),
        value: 'UF_COMPLETED',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_NAME'),
        value: 'UF_NAME',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_QUANTITY'),
        value: 'UF_QUANTITY',
        sortable: false,
      },
      {
        text: vm.$t('props.UF_PRICE'),
        value: 'UF_PRICE',
        sortable: false,
      },
    ],
  }),
  computed: {
    id() {
      return +this.$route.params.id;
    },
    goodsGroups() {
      return this.$store.getters.getGroupsGoods;
    },
    payments() {
      return this.$store.getters.getPayments;
    },
    propsSystem() {
      return this.props.filter((item) => item.group === 'system');
    },
    propsInfo() {
      return this.props.filter((item) => item.group === 'info');
    },
    propsDopInfo() {
      return this.props.filter((item) => item.group === 'dopInfo');
    },
    goods() {
      const findProp = this.props.find((prop) => prop.code === 'goods');

      return (findProp) ? findProp.value : [];
    },
    paymentsClient() {
      if (this.clientSelect) {
        const payments = [...this.payments];

        if (this.clientSelect.UF_DEPOSIT !== '1') {
          const index = payments.findIndex((item) => item.id === 2);

          payments.splice(index, 1);
        }

        return payments;
      }

      return [];
    },
    allSum() {
      const findProp = this.props.find((prop) => prop.code === 'goods');

      if (findProp) {
        return findProp.value.reduce((sum, good) => {
          const total = sum + Math.round(good.UF_QUANTITY * good.UF_PRICE);
          return total;
        }, 0);
      }

      return 0;
    },
    orderSum() {
      return this.deliveryPrice + this.receiptPrice + this.allSum;
    },
    paidSumComp() {
      const findProp = this.props.find((prop) => prop.code === 'UF_PAID_SUM');

      return (findProp) ? findProp.value : 0;
    },
    paidSumStayComp() {
      return this.orderSum - this.paidSumComp;
    },
  },
  methods: {
    print() {
      window.print();
    },
    getProps() {
      this.loading = true;
      this.showHistory = false;

      this.$store.dispatch('getOrder', this.id).then((response) => {
        const { result } = response.data.data;
        this.photosLoad = response.data.data.photos;

        this.deliveryPrice = +result.UF_DELIVERY_SUM;
        this.defaultDeliveryPrice = +result.UF_DELIVERY_SUM;

        this.receiptPrice = +result.UF_RECEIPTS_SUM;
        this.defaultReceiptPrice = +result.UF_RECEIPTS_SUM;

        if (result) {
          this.props = this.props.map((item) => {
            const prop = item;

            if (result[prop.code]) {
              prop.value = result[prop.code];
            }

            return prop;
          });

          this.loading = false;
        }
      });
    },
  },
  created() {
    this.getProps();
  },
};
</script>

<style lang="scss">
  .print {
    .theme--light.v-label--is-disabled {
      color: rgba(0, 0, 0, .6);
    }
    .theme--light.v-input--is-disabled input,
    .theme--light.v-input--is-disabled textarea,
    .theme--light.v-select.v-input--is-disabled .v-select__selections,
    .theme--light.v-select .v-select__selection--disabled {
      color: rgba(0, 0, 0, .87);
    }
  }

  @media print {
    .no-print {
      display: none;
    }
  }
</style>
